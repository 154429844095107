import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DB from './db/db.json';

import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';

class Project extends Component{
    constructor(props) {   
        super(props);
        this.slug = this.props.match.params.slug;
        let project = this.getProject();

        this.loader = React.createRef();

        this.state = {
            pk : project[0].pk,
            title : project[0].fields.title,
            description : project[0].fields.description,
            year : project[0].fields.year,
            type : project[0].fields.type,
            partner : project[0].fields.partner,
        };

        this.images = this.getImages(this.state.pk);
    }

    getProject () {
        let project = [];
        const slug = this.slug;

        DB.forEach(function (el) {
          if(el.model === 'website.project' && el.fields.slug === slug) {
              project.push(el);
          } 
        });
        return project;
    }

    getImages (pk) {
        const raw = DB
                    .filter(el => el.model === "website.picture")
                    .filter(el => (el.fields.project === pk && (el.fields.mode === "HalfCol" || el.fields.mode === "FullCol")));
        return raw;
    }

    render () {
        let details;
        
        if (this.state.partner !== "") details = <><h5>{this.state.year}<span></span>{this.state.type}<span></span></h5><h5 dangerouslySetInnerHTML={{__html: this.state.partner}}></h5></>
        else  details = <h5>{this.state.year}<span></span>{this.state.type}</h5>

        let imgArray = []; 

        for (let i=0; i<this.images.length; i++) {

            let img = this.images[i];
            
            let colNumb = 12;
            let picContent; 
            let picStyle = { backgroundColor: img.fields.bgStyle }

            if (img.fields.mode === "HalfCol") colNumb = 6;

            if (img.fields.type === "Image" || img.fields.type === "SVG") picContent = <img src={`${process.env.PUBLIC_URL}/${img.fields.url}`} alt={img.fields.alt} width = {img.fields.width}  height = {img.fields.height}/>
            else if (img.fields.type === "Video")  picContent = <video playsInline autoPlay muted loop width = {img.fields.width} height = {img.fields.height}> <source src={img.fields.url} type="video/mp4" /> Your browser does not support the video tag. </video>
            else picContent = <video playsInline controls width = {img.fields.width} height = {img.fields.height}> <source src={img.fields.url} type="video/mp4" /> Your browser does not support the video tag. </video>

            if (img.fields.cssStyles === "" && img.fields.extraCol === "") {
                imgArray.push(
                    <Col xs={colNumb}>
                        <div className={"img-container "+img.fields.colorClasses+" "+img.fields.gridClasses}>
                            {picContent}
                        </div>
                    </Col>
                )
            } else if  (img.fields.cssStyles !== "" && img.fields.extraCol === "") {
                imgArray.push(
                    <Col xs={colNumb}>
                        <div className={"img-container "+img.fields.colorClasses+" "+img.fields.gridClasses} style={picStyle}>
                            {picContent}
                         </div>
                    </Col>
                )
            } else if (img.fields.cssStyles === "" && img.fields.extraCol !== "") {
                imgArray.push(
                    <Col xs={colNumb}>
                        <div className={"img-container "+img.fields.colorClasses+" "+img.fields.gridClasses}>
                            <div className={img.fields.extraCol}>
                                {picContent}
                            </div>
                        </div>    
                    </Col>
                )
            } else {
                imgArray.push(
                    <Col xs={colNumb}>
                        <div className={"img-container "+img.fields.colorClasses+" "+img.fields.gridClasses} style={picStyle}>
                            <div className={img.fields.extraCol}>
                                {picContent}
                            </div>
                        </div>
                    </Col>
                )
            }

            if (img.fields.description !== "") {
                imgArray.push(
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} sm={10} md={8} lg={6} dangerouslySetInnerHTML={{__html: img.fields.description}}></Col>
                        </Row>
                    </Col>
                )
            }
        }

        return(
            <> 
            <Loader ref={this.loader}/>
            <Container fluid className="project-content">
                <Header inProject="true"/>
                <Row>
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <h1>{this.state.title}</h1>
                        {details}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={10} md={8} lg={6} dangerouslySetInnerHTML={{__html: this.state.description}}></Col>
                </Row>
                <Row>
                    {imgArray}
                </Row>
                <Footer/>
            </Container>
            </>
        );
    }

    componentDidMount () {
        document.onreadystatechange = () => {
          const docState = document.readyState;
          if (docState === 'complete') {
            setTimeout(()=>{this.loader.current.hide()}, 100);
          }
        }
      }
}

export default Project;