import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import DB from './db/db.json';

import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';
import ProjThumb from './components/ProjThumb';


class App extends Component{
  constructor(props) {
    super(props);
    let highlightedProject = this.getHighlightProjects (); // Array de projectos destaque
    const projectThumbs = []; // array de componentes projThumb

    // Para cada projecto na lista de highlightProject cria um componente ProjThumb
    for (let project of highlightedProject) {
      const key = project.pk;
      const hp = <ProjThumb id={"project-"+key} key={key} slug={project.fields.slug} title={project.fields.title} year={project.fields.year} partner={project.fields.partner} type={project.fields.type} url={project.fields.url} alt={project.fields.alt} width={project.fields.width} height={project.fields.height} format={project.fields.format} preload={project.fields.preload}/>
      projectThumbs.push(hp);
    }

    this.loader = React.createRef();

    // ?????
    this.state = {
      projects: projectThumbs
    }
  }
  
  // Procura os Highlighted projects e adiciona ao array highlightProject
  getHighlightProjects () {
    let projects = [];
    
    DB.forEach( (el)=> {
      if(el.model === 'website.project' && el.fields.highlight === true) {
        projects.push(el);
      }
    });
    return projects;
  }

  render () {
    return (
      <>
      <Loader ref={this.loader}/>
      <Container fluid>
        <Header inProject="true"/>
          <Row>
              {this.state.projects}
          </Row>
        <Footer/>
      </Container>
      </>
    );
  }

  componentDidMount () {
    document.onreadystatechange = () => {
      const docState = document.readyState;
      if (docState === 'complete') {
        setTimeout(()=>{this.loader.current.hide()}, 100);
      }
    }
  }
}

export default App;
