import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


class ProjThumb extends Component{
    constructor(props) {   
        super(props);
        this.state = {
            slug: props.slug,
            title: props.title,
            year: props.year,
            partner: props.partner,
            type: props.type,
            url: props.url,
            alt: props.alt,
            width: props.width,
            height: props.height,
            format: props.format,
            preload: props.preload
        }
    }

    render () {
        let thumbnail; 
        if (this.state.format === "Video")  thumbnail = <video playsInline autoPlay muted loop width = {this.state.width} height = {this.state.height}> <source src={this.state.url} type="video/mp4" /> Your browser does not support the video tag. </video>
        else  thumbnail = <img src={`${process.env.PUBLIC_URL}/${this.state.url}`} alt={this.state.alt} width = {this.state.width} height = {this.state.height}/>

        let details; 
        if (this.state.partner !== "") details = <h2><span dangerouslySetInnerHTML={{__html: this.state.partner}}></span> in {this.state.year}</h2>
		else details = <h2>{this.state.year}</h2>

        return(
            <Col md={6}>
                <a className="card" href={"/"+this.state.slug}>
                    {thumbnail}

                    <div className="overlay">
						<div className="card-title">
			    			<h2>{this.state.title}</h2>
						</div>
						<div className="card-body">
	    					<h2>{this.state.type}</h2>
							{details}
                            </div>
					</div>
                </a>
            </Col>
        );
    }
}

export default ProjThumb;