import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DB from './db/db.json';

import Header from './components/Header';
import Footer from './components/Footer';


class Archive extends Component {
  constructor(props) {
    super(props);
    const allYears = this.getYears();
    this.allYearsList = allYears;

    const uniqueYears = this.getUniqueYears();
    this.uniqueYearsList = uniqueYears;
    this.uniqueYearsList.sort().reverse();

    const projects = this.getProjects();
    this.displayProjs = projects;
  }

  getUniqueYears() {
    let cleanList = [];
    for(let i=0; i<this.allYearsList.length; i++) {
      let repeat = false;
      for (let a=0; a<cleanList.length; a++) {
        if (cleanList[a] ===  this.allYearsList[i]) {   
          repeat = true;
          break;
        }
      }
      if (repeat === false) cleanList.push(this.allYearsList[i]);
      repeat = false;
    }
    return cleanList;
  }

  getYears () {
    let years = [];
    DB.forEach(function (el) {
      if(el.model === 'website.project') {
        years.push(el.fields.year);
      }
    });
    return years;
  }

  getProjects () {
    let projects = [];
    for (let y of this.uniqueYearsList) {
      //projects.push(<h3>{y}</h3>)
      DB.forEach(function (el) {
        if(el.model === 'website.project' && el.fields.year === y) {
          projects.push(<a href={"archive/"+el.fields.slug}><p>{el.fields.year}<span></span>{el.fields.title}</p></a>);
        }
      });
    }
    return projects;
  }

  render () {
    //console.log(this.state.projects);
    let display = [];
    for(let i=0; i<this.displayProjs.length; i++) {
      display.push(<div className="mb-20">{this.displayProjs[i]}</div>)
    }

    return (
      <Container fluid>
        <Header/>
          <Row id="archive">
            <Col>
            <h1>Archive</h1>
            {this.displayProjs}
            </Col>
          </Row>
        <Footer/>
      </Container>
    );
  }
}

export default Archive;
