import React, {Component} from 'react';

class Loader extends Component {
    constructor(props) {
        super(props)
    };

    hide () {
        const loaderComp = document.querySelector("#loader");
        loaderComp.style.opacity = 0;

        loaderComp.addEventListener('transitionend', (e) => {
            e.target.style.display = "none";
          });

        document.querySelector("body").style.height = 100;
        document.querySelector("body").style.overflowY = "auto";
    }

    render () {
        return(
            <div id="loader">
                <img src="static/loading.svg" alt="loading"/>
             </div>
        )
    }
}

export default Loader;
