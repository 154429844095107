import React, {Component, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Switch, Route, Router } from 'react-router-dom';
import history from './history';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import Archive from './Archive';
import Project from './Project';
import reportWebVitals from './reportWebVitals';

const Page = (props) => {
  useEffect( () => {
    ReactGA.initialize("UA-131844696-1", {debug: false});

    props.history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });


    ReactGA.exception({
      description: 'An error ocurred',
      fatal: true
    });

  });

  return (
    <Router history={props.history}>
      <Switch>
        <Route exact path="/archive/404" component={Archive}/> {/*project not found*/}
        <Route path="/archive/:slug" component={Project}/>
        <Route path="/archive" component={Archive}/>
        <Route path="/:slug" component={Project}/>
        <Route path="/" component={App} />
        <Route path="*" component={App}/> {/*not found router*/}
      </Switch>
    </Router>
  );
}

ReactDOM.render(<Page history={history}/>,document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
