import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer (props) {
    return(
        <footer>
            <Row>
                <Col>
                    {/*<a href="/archive">Archive</a>*/}
                    <a href="mailto:beatriz@beatrizcorreia.com" target="_blank" rel="noreferrer noopener">Contact</a>
                    <a href="https://dribbble.com/beatrizcorreia" target="_blank" rel="noreferrer noopener">Dribbble</a>
                    <a href="https://instagram.com/abeatrizcorreia" target="_blank" rel="noreferrer noopener">Instagram</a>
                    <a href="https://pt.linkedin.com/in/beatriz-correia-596a6a125" target="_blank" rel="noreferrer noopener">Linkedin</a>
                </Col>
            </Row>
        </footer>
    );
}

export default Footer;