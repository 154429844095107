import React, {Component} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Header extends Component {
    constructor(props) {   
        super(props); 
        this.triggerAbout = this.triggerAbout.bind(this); 
        this.about = false; 
        this.state = {
            inProject: props.inProject
        };
    }

    triggerAbout (ev) {
        if (this.about === false) {
            document.getElementById("about").style.right = 0;
            document.getElementById("nav-link-about").classList.add("active");
            this.about = true;
        } else if (this.about === true) {
            if (window.width > 962) document.getElementById("about").style.right = "-50vw";
            else if (window.width > 576) document.getElementById("about").style.right = "-75vw";
            else document.getElementById("about").style.right = "-100vw";
            document.getElementById("nav-link-about").classList.remove("active");
            this.about = false;
        }
        ev.preventDefault();
    }
    
    render () {
        let navContent; 
        navContent =  <nav className="d-flex">
                            <a href="/" className="nav-link">Beatriz Correia</a>
                            <a href="" id="nav-link-about" className="nav-link" onClick = {this.triggerAbout}>About & Contacts</a>
                        </nav>
        
        return(
            <>
            {navContent}
            <Container fluid id="about">
                <button id="close" onClick={this.triggerAbout} aria-label="Close"></button>
                    <Row>
                    <Col xs={12} md={11}>
                        <p>I’m Beatriz, a graphic designer with a special interest in web design, creative coding and visual identity. Currently, I'm based in Coimbra, Portugal.</p>
                        <p>Originally from the Azores islands, I moved to Coimbra in 2011 to study Design+Multimedia. I graduated in September of 2016 with a master thesis in the field of Information Aesthetics. During October of the same year, I started working as a graphic and web designer at <a href="http://fba.pt" target="_blank" rel="noreferrer noopener">FBA.</a> In my spare time, I also do freelance work as a UI/UX designer and front-end developer.</p>
                        <p>You can contact me by <a href="mailto:contact@beatrizcorreia.com">e-mail</a>. You can also reach me through <a href="https://dribbble.com/beatrizcorreia" target="_blank" rel="noreferrer noopener">dribbble</a>, <a href="https://instagram.com/abeatrizcorreia" target="_blank" rel="noreferrer noopener">instagram</a> and <a href="https://pt.linkedin.com/in/beatriz-correia-596a6a125" target="_blank" rel="noreferrer noopener">linkedin</a>.</p>
                        <div id="cv">
                            <h4>Work & Studies</h4>
                            <h5>2016–today</h5>
                            <p>Visual Designer at <a href="http://fba.pt" target="_blank" rel="noreferrer noopener">FBA.</a></p>
                            <h5>2014–2020</h5>
                            <p>Graphic Designer at Há Baixa Association</p>
                            <h5>2014–2016</h5>
                            <p>Master's Degree in Design+Multimedia</p>
                            <h5>2011–2014</h5>
                            <p>Bachelor's Degree in Design+Multimedia</p>

                            <h4>Main tools</h4>
                            <p>Sketch, Figma, Adobe Illustrator, Adobe InDesign, Adobe After Effects, Adobe Photoshop, Marvel, InVision, Zeplin, Affinity Designer</p>

                            <h4>Frameworks & programming languages</h4>
                            <p>Bootstrap 4, Foundation, HTML5 & CSS3, Javascript & JQuery, Processing, InDesign Scripting</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}

export default Header;